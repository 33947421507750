.fabrics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.fabrics.sizeXS {
  row-gap: 4px;

  .fabric {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 14px;
      height: 14px;

      &:hover {
        .imageWrapper {
          box-shadow: 0 0 0 1px var(--color-white), 0 0 0 2px var(--color-dark-gray);
        }
      }
    }

    &.selected {
      .imageWrapper {
        box-shadow: 0 0 0 1px var(--color-white), 0 0 0 2px var(--color-dark-gray);
      }
    }

    &.disable:not(&.selected) .fabricContainer:hover {
      .imageWrapper {
        box-shadow: none;
      }
    }
  }
}

.fabrics.sizeS {
  row-gap: var(--space-xs);

  .fabric {
    margin-right: var(--space-xs);

    &:last-child {
      margin-right: 0;
    }
    .fabricContainer {
      width: 16px;
      height: 16px;

      &:hover {
        .imageWrapper {
          box-shadow: 0 0 0 2px var(--color-white), 0 0 0 3px var(--color-dark-gray);
        }
      }
    }

    &.selected {
      .imageWrapper {
        box-shadow: 0 0 0 2px var(--color-white), 0 0 0 3px var(--color-dark-gray);
      }
    }

    &.disable:not(&.selected) .fabricContainer:hover {
      .imageWrapper {
        box-shadow: none;
      }
    }
  }
}

.fabrics.sizeM {
  row-gap: var(--space-n);

  .fabric {
    margin-right: var(--space-n);

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 25px;
      height: 25px;
      &:hover {
        .imageWrapper {
          box-shadow: 0 0 0 3px var(--color-white), 0 0 0 4px var(--color-dark-gray);
        }
      }
    }

    &.selected {
      .imageWrapper {
        box-shadow: 0 0 0 3px var(--color-white), 0 0 0 4px var(--color-dark-gray);
      }
    }

    &.disable:not(&.selected) .fabricContainer:hover {
      .imageWrapper {
        box-shadow: none;
      }
    }
  }
}

.fabrics.sizeL {
  row-gap: var(--space-n);

  .fabric {
    margin-right: var(--space-n);

    &:last-child {
      margin-right: 0;
    }

    .fabricContainer {
      width: 40px;
      height: 40px;
      &:hover {
        .imageWrapper {
          box-shadow: 0 0 0 4px var(--color-white), 0 0 0 5px var(--color-dark-gray);
        }
      }
    }

    &.selected {
      .imageWrapper {
        box-shadow: 0 0 0 4px var(--color-white), 0 0 0 5px var(--color-dark-gray);
      }
    }

    &.disable:not(&.selected) .fabricContainer:hover {
      .imageWrapper {
        box-shadow: none;
      }
    }
  }
}

.fabricContainer {
  display: block;
}

.imageWrapper {
  position: relative;
  z-index: 2;

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  overflow: hidden;

  background-size: cover;
  border: 1px solid var(--color-light-gray);
  border-radius: 50%;

  transition: box-shadow 0.2s ease-in-out;
}

.fabric {
  display: flex;
  align-items: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.counter {
  margin-left: var(--space-s);

  color: var(--color-dark-gray);
  font-size: var(--size-s);
  line-height: 1.5;
  white-space: nowrap;

  cursor: pointer;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-black);
  }
}

.circleSvg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
