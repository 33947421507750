.container {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
  width: max-content;
  padding: 7px var(--space-s);

  background: var(--color-light-gray);
  border-radius: 20px;
}

.container:hover {
  background: var(--color-medium-gray);
}

.container:hover .icon {
  background: var(--color-medium-gray);
}

.onlyIcon {
  padding: 7px;
}

.title {
  color: var(--color-grafit);
  font-weight: 500;
  font-size: var(--size-s);
  font-family: var(--font-main);
  font-style: normal;
  line-height: 150%;
}

.icon {
  width: 22px;
  height: 22px;

  background: transparent;
}

@media (--desktop-m) {
  .container {
    padding: var(--space-xxs) var(--space-xs);
  }

  .onlyIcon {
    padding: var(--space-xxs);
  }

  .title {
    font-size: var(--size-xs);
  }
}
