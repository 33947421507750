/*  NOTE:
*   Если изменяется высота галереи, то необходимо внести правки в компоненте ProductCard
*   для плейсхолдера галереи, иначе размеры начнут отличаться, что приведёт к визуальным багам в каталоге
*   Этот плейсхолдер отображается вместо галереи, когда карточка в каталоге выходит из области видимости,
*   чтобы сократить кол-во элементов на странице и улучшить производительность
*/

.previews {
  --proportion: var(--image-proportion-landscape);

  position: relative;

  &:after {
    display: block;
    width: 100%;
    padding-bottom: var(--proportion);

    content: '';
  }

  &.landscape {
    --proportion: var(--image-proportion-landscape);
  }

  &.portrait {
    --proportion: var(--image-proportion-portrait);
  }
}

.link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;
}

.content {
  width: 100%;
  height: 100%;
}

.gallery {
  margin: 0 -1px;
  overflow: hidden;

  background-color: var(--color-light-gray);
  border-radius: 5px;
}

.item {
  width: 100%;
  padding: 0 1px;
}

.image {
  overflow: hidden;

  background-color: var(--color-light-gray);
  border-radius: 5px;
}
