.tag {
  display: flex;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  overflow: hidden;

  background-color: #ffddca;
  border-radius: 50px;
}

.image {
  width: 25px;
  height: 26px;

  background-color: transparent;
}

.text {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-xs);
  line-height: 1.5;
  text-align: center;

  transform: translateY(-25%);

  &.withAnimation {
    animation: animation 6s infinite linear;
  }
}

.codeWrapper {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
  padding: 3px 7px 3px 3px;
}

.textWrapper {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.discount {
  padding: 4px;

  color: var(--color-white);

  background-color: #d7040480;
  border-radius: 15px;
}

@keyframes animation {
  0% {
    transform: translateY(-50%);
  }
  /* сдвиг */
  2% {
    transform: translateY(-25%);
  }
  /* ожидание */
  48% {
    transform: translateY(-25%);
  }
  /* сдвиг */
  51% {
    transform: translateY(25%);
  }
  /* ожидание */
  98% {
    transform: translateY(25%);
  }
  /* сдвиг */
  100% {
    transform: translateY(50%);
  }
}
