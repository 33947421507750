.title {
  margin: 0;

  font-weight: 500;
  font-size: var(--size-b);
  font-family: var(--font-hero);
  line-height: 1.5;
  text-align: center;
  word-break: break-word;

  &.bordered {
    padding: var(--space-xl) 0;

    border-bottom: 1px solid var(--color-medium-gray);
  }
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xxl);
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-xl);

    &.bordered {
      padding: var(--space-n) 0;
      padding-bottom: var(--space-l);
    }
    &.leftAlign {
      text-align: left;
    }
  }
}
