.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 81px;
  height: 32px;
  padding: 7px var(--space-xs);

  color: var(--color-white);
  font-size: var(--size-xs);

  background-color: var(--color-dirty);
  border-radius: var(--space-xxe);
}
