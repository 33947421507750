.container {
  display: grid;
  grid-auto-flow: column;
  gap: var(--space-xs);
  align-items: center;
  justify-content: start;

  &.promo {
    .actualPrice {
      color: var(--color-red);
    }
  }
}

.actualPrice {
  font-weight: 500;
  font-size: var(--size-m);
}

.expiredPrice {
  font-size: var(--size-n);
}

.discount {
  font-size: 13px;
}

@media (--mobile-m) {
  .container {
    grid-template-areas: 'actual expired discount';
    row-gap: 0;

    &.uzb {
      grid-template-areas:
        'expired discount'
        'actual actual';
      justify-items: start;
    }
  }

  .actualPrice {
    grid-area: actual;
  }

  .expiredPrice {
    grid-area: expired;
  }

  .discount {
    grid-area: discount;
  }
}

@media (--mobile) {
  .container {
    grid-template-areas: 'actual expired discount';

    &.uzb {
      grid-template-areas:
        'expired discount'
        'actual actual';
      justify-items: start;
    }

    &.mini {
      grid-template-areas:
        'expired'
        'actual';
      justify-items: start;

      .discount {
        display: none;
      }

      &.longPrice {
        .actualPrice {
          flex-basis: 100%;
        }
      }
    }

    &.uzb {
      .actualPrice {
        font-size: var(--size-s);
      }
    }
  }

  .actualPrice {
    font-size: var(--size-n);
    line-height: 1.5;
  }

  .expiredPrice {
    font-size: var(--size-xs);
    line-height: 1.5;
  }

  .expiredSpace::before {
    visibility: hidden;

    content: '_';
  }
}
