.fabricsWrapper {
  display: flex;
  align-items: center;
  height: 26px;
}

.fabricsPlaceholder {
  height: 26px;
}

.fabricsMore {
  display: flex;
  margin-left: var(--space-xs);

  color: var(--color-dark-gray);

  font-size: var(--size-n);
  line-height: 1.5;
  white-space: nowrap;
}

@media (--mobile) {
  .fabricsMore {
    margin-left: var(--space-xxs);

    font-size: var(--size-xs);
  }
}
