.placeholderPreview {
  position: relative;

  &:after {
    display: block;
    width: 100%;
    padding-bottom: var(--proportion);

    content: '';
  }

  &.landscape {
    --proportion: var(--image-proportion-landscape);
  }

  &.portrait {
    --proportion: var(--image-proportion-portrait);
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding-bottom: 0;
}
